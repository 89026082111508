import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import { push } from "connected-react-router";
import clsx from "clsx";

import ProductCount from "../../common/product-count";

import { useAppDispatch, useAppSelector } from "../../../store/store";
import displayCurrencySign from "../../../utils/currencies-signs";
import { config } from "../../../config";
import { UpdatedProduct } from "./cart";

import { ReactComponent as CloseIcon } from "../../../static/icons/close.svg";
import usersStore from "../../../store/users-store";
import { isMobile } from "react-device-detect";
import languagesStore from "../../../store/languages-store";
import authFormStore from "../../../store/auth-form-store";
import { countProductPrice, countProductDiscountPrice } from "../../../utils/helper";

const useStyles = makeStyles(
  theme => ({
    root: {
      position: "relative",
      width: "100%",
      boxSizing: "border-box",
      padding: "0 15px",
      display: "grid",
      gridTemplateColumns: "2fr 1fr",
      [theme.device.mobile()]: {
        padding: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      },
    },
    shoppingBag: {
      borderBottom: `1px solid ${theme.colors.grayText}`,
      paddingBottom: 10,
      "&:last-child": {
        padding: 0,
        border: "none",
      },
      [theme.device.desktop()]: {
        gridTemplateColumns: "35px 105px 150px 2.2fr 0.8fr",
        padding: 0,
        border: "none",
      },
    },
    shoppingBagItem: {
      [theme.device.desktop()]: {
        marginLeft: "auto",
        alignItems: "flex-start",
        // marginTop: 15,
      },
    },
    productTotal: {
      margin: "0 auto",
      marginTop: 3,
    },
    productInfo: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-end",
      boxSizing: "border-box",
      [theme.device.desktop()]: {
        paddingRight: 15,
      },
    },
    productInfoShoppingBag: {
      justifyContent: "flex-start",
    },
    productView: {
      boxSizing: "border-box",
      height: "80%",
      width: 170,
      alignSelf: "center",
      marginLeft: "auto",
      [theme.device.mobile()]: {
        height: "auto",
      },
    },
    image: {
      width: "100%",
      height: "100%",
      cursor: "pointer",
      objectFit: "contain",
    },
    productViewShoppingBag: {
      marginLeft: "auto",
      width: 170,
      [theme.device.desktop()]: {
        height: 170,
      },
    },
    productColor: {
      padding: "10px 0",
      color: theme.colors.grayText,
      display: "flex",
      alignItems: "center",
      fontSize: 20,
      fontWeight: 600,
    },
    closeIcon: {
      position: "absolute",
      left: 5,
      top: 10,
      minWidth: 12,
      minHeight: 12,
      cursor: "pointer",
      ...theme.utils.svgStroke(theme.colors.green),
      [theme.device.mobile()]: {
        position: "relative",
        alignSelf: "flex-start",
        minWidth: 20,
        minHeight: 20,
      },
    },
    closeIconShoppingBag: {
      position: "relative",
    },
    greenText: {
      color: theme.colors.green,
      fontWeight: 600,
      fontSize: 20,
    },
    smallText: {
      fontSize: 16,
    },
    productName: {
      direction: "rtl",
      fontWeight: 700,
      fontSize: 25,
      cursor: "pointer",
      width: "95%",
    },
    productNameShoppingBag: {
      wordBreak: "break-word",
      paddingLeft: 20,
      boxSizing: "border-box",
      direction: "rtl",
      [theme.device.mobile()]: {
        paddingLeft: 30,
      },
    },
    productPrice: {
      fontSize: 23,
      cursor: "pointer",
    },
    productImg: {},
    outOfStock: {
      position: "absolute",
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      textAlign: "center",
      borderRadius: 20,
      color: "#fff",
      padding: 10,
    },
    warning: {
      fontSize: 12,
      color: theme.colors.red,
      opacity: 0,
      transition: "0.2s",
    },

    warningActive: {
      opacity: 1,
      transition: "0.2s",
    },
    activeDesktop: {
      [theme.device.mobile()]: {
        display: "none",
      },
    },
    activeMobile: {
      [theme.device.desktop()]: {
        display: "none",
      },
    },
  }),
  { name: "cart-item" }
);

type TProps = {
  cartProduct: UpdatedProduct;
  shoppingBag?: boolean;
  // setActiveCart?: (activeCart: boolean) => void;
};

const CartItem = ({ cartProduct, shoppingBag }: TProps): JSX.Element => {
  const [productCount, setProductCount] = useState<number>(cartProduct.count!);
  const [warningVisible, setWarningVisible] = useState(false);

  const { activeCategory, userId, cartItems, lang } = useAppSelector(state => ({
    activeCategory: state.categories.activeCategory,
    cartItems: state.user.data?.cartItems,
    lang: state.languages.language,
    userId: state.user.data?.id,
  }));

  const productName: string = cartProduct.title + " " + cartProduct.name;

  const title = productName;

  const dispatch = useAppDispatch();
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const classes = useStyles();

  const onCloseBtnClick = useCallback(() => {
    const updatedCartItems = cartItems?.filter(cartItem => {
      if (cartItem.productId !== cartProduct.id) {
        return {
          id: cartItem.productId,
          productId: cartItem.productId,
          productName: cartItem.productName,
          productCount: cartItem.productCount,
          price: cartItem.price,
        };
      }

      return false;
    });

    dispatch(usersStore.actions.updateUserCart({ userId: userId!, cartItems: updatedCartItems! }));
  }, [cartProduct]);

  const goToSpProduct = () => {
    const url = `${config.routes.product}/${cartProduct.catalogNumber}`;
    dispatch(push(!!activeCategory ? url + `?category=${activeCategory}` : url, true));

    dispatch(authFormStore.actions.toggleVisibility("authFormIsVisible", false));
  };

  useEffect(() => {
    const maxQuantityIsReached = productCount - cartProduct?.quantity > 0;
    const productCountToCart = maxQuantityIsReached ? cartProduct.quantity : productCount;

    const updatedCartItems = cartItems?.map(cartItem => {
      if (cartItem.productId === cartProduct.id)
        return {
          ...cartItem,
          productCount: productCountToCart,
        };

      return cartItem;
    });

    dispatch(usersStore.actions.updateUserCart({ userId: userId!, cartItems: updatedCartItems! }));
  }, [dispatch, productCount]);

  useEffect(() => {
    if (!cartProduct.isPublished) {
      onCloseBtnClick();
    }
  }, [cartProduct]);

  const isAuth = useAppSelector(({ user }) => user.data?.approvedAgent);
  const discount = useAppSelector(({ user }) => user.data?.discount);
  // let {price} = isAuth ? {price: cartProduct.storePrice} : countProductDiscountPrice(cartProduct);
  let { price } = isAuth
    ? countProductPrice(isAuth, cartProduct, discount)
    : countProductDiscountPrice(cartProduct);

  return (
    <div className={clsx(classes.root, shoppingBag && classes.shoppingBag)}>
      <CloseIcon
        className={clsx(classes.closeIcon, shoppingBag && classes.closeIconShoppingBag)}
        onClick={onCloseBtnClick}
      />

      {shoppingBag && (
        <div
          className={clsx(
            classes.greenText,
            classes.shoppingBagItem,
            classes.productTotal,
            classes.activeDesktop
          )}
        >
          {displayCurrencySign(lang)}
          {(price! * cartProduct?.count!).toFixed(2)}
        </div>
      )}

      {shoppingBag && (
        <ProductCount
          className={{ root: clsx(classes.shoppingBagItem, classes.activeDesktop) }}
          productQuantity={cartProduct?.quantity}
          setWarningVisible={setWarningVisible}
          setCount={setProductCount}
          productId={cartProduct.id}
          big
        />
      )}
      <div
        className={clsx(
          classes.productView,
          shoppingBag && classes.productViewShoppingBag,
          classes.activeMobile
        )}
      >
        <img
          className={classes.image}
          src={cartProduct.mainPicture?.url!}
          alt="product"
          onClick={goToSpProduct}
        />

        {cartProduct?.quantity === 0 && (
          <div
            className={classes.outOfStock}
            style={{
              top: cartProduct?.availabilityDate ? "20%" : "22%",
              right: cartProduct?.availabilityDate ? "13%" : "12%",
            }}
          >
            {activePhrases && activePhrases["out_of_stock"]}

            {!cartProduct?.availabilityDate && (
              <>
                <br /> <span>{activePhrases && activePhrases["available_on"]}</span>
                <br /> <span>{cartProduct?.availabilityDate}</span>
              </>
            )}
          </div>
        )}
      </div>

      <div className={clsx(classes.productInfo, shoppingBag && classes.productInfoShoppingBag)}>
        <div
          className={clsx(
            classes.greenText,
            classes.productName,
            shoppingBag && classes.productNameShoppingBag
          )}
          onClick={goToSpProduct}
        >
          {title}
        </div>

        <div className={clsx(classes.greenText, classes.productPrice)} onClick={goToSpProduct}>
          {displayCurrencySign(lang)}
          {price?.toFixed(2)}
          {/* {cartProduct?.price?.toFixed(2)} */}
        </div>

        <div className={classes.productColor}>
          <div>{cartProduct.color}</div>

          <div className={classes.greenText}>&nbsp;:{activePhrases && activePhrases["color"]}</div>
        </div>

        {!shoppingBag && (
          <div className={classes.activeDesktop}>
            <ProductCount
              productQuantity={cartProduct?.quantity}
              setWarningVisible={setWarningVisible}
              setCount={setProductCount}
              productId={cartProduct.id}
              big
            />
          </div>
        )}

        <div className={classes.activeMobile}>
          <ProductCount
            productQuantity={cartProduct?.quantity}
            setWarningVisible={setWarningVisible}
            setCount={setProductCount}
            productId={cartProduct.id}
            big
          />
        </div>

        <div className={clsx(classes.warning, warningVisible && classes.warningActive)}>
          {activePhrases &&
            activePhrases["You_can_not_exceed_the_available_quantity_of_the_product"]}
        </div>
      </div>

      <div
        className={clsx(
          classes.productView,
          shoppingBag && classes.productViewShoppingBag,
          classes.activeDesktop
        )}
      >
        <img
          className={classes.image}
          src={cartProduct.mainPicture?.url!}
          alt="product"
          onClick={goToSpProduct}
        />

        {cartProduct?.quantity === 0 && (
          <div
            className={classes.outOfStock}
            style={{
              top: cartProduct?.availabilityDate ? "20%" : "22%",
              right: cartProduct?.availabilityDate ? "13%" : "12%",
            }}
          >
            {activePhrases && activePhrases["out_of_stock"]}

            {!cartProduct?.availabilityDate && (
              <>
                <br /> <span>{activePhrases && activePhrases["available_on"]}</span>
                <br /> <span>{cartProduct?.availabilityDate}</span>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CartItem;
