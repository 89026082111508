import {
  createAction,
  createAsyncThunk,
  createReducer,
  createSelector,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";

import productsService from "../services/products";
import { Product, Review } from "../services/products/types";
import strapiService from "../services/strapi";
import { getDefaultResourceState, Resource } from "./resource-state";
import { ApplicationState } from "./root-reducer";
import { TThunk, useAppSelector } from "./store";
import { CategoryLevel } from "../services/categories/types";

type State = {
  productsData: Resource<Product>;
  isFullyLoaded: boolean;
  requestedCategoryIds: number[];
  activeProduct: Product | null;
};

type UpdateProductProps = {
  catalogNumber: string;
  reviews: Review[];
};

const initialState: State = {
  productsData: getDefaultResourceState(),
  // indicates if /allBrandProducts is fetch done, until then we should request category products
  isFullyLoaded: false,
  // loaded categories cannot be derived from products because subcategories are mapped in the backend only
  requestedCategoryIds: [],
  activeProduct: null,
};

const fetchProducts: TThunk<Product[]> = createAsyncThunk(
  "products/fetch",
  async () => await productsService.getProducts(),
  {
    condition: (_, { getState }) => {
      const state = getState();
      return !state.products.productsData.loaded;
    },
  }
);

const fetchProductsByCategory: TThunk<Product[], { categoryLevel: CategoryLevel, categoryId: number}> = 
createAsyncThunk(
  "productsByCategory/fetch",
  async ({ categoryId, categoryLevel }) => await productsService.getProductsByCategory(categoryLevel, categoryId),
  {
    condition: ({ categoryId }, { getState }) => {
      const state = getState();
      const requestedCategoryIds = state.products.requestedCategoryIds

      const isCategoryLoaded = requestedCategoryIds.includes(categoryId) 
      return !(isCategoryLoaded || state.products.isFullyLoaded)
    },
  }
);

const updateProduct: TThunk<Product[] | undefined, UpdateProductProps[]> = createAsyncThunk(
  "product/update",
  async productsArray => await strapiService.updateProduct(productsArray)
);

const setActiveProduct = createAction("products/active-product", (product: Product) => ({
  payload: product,
}));

const addRequestedCategory = createAction("products/push-req", (categoryId: number) => ({
  payload: categoryId,
}));

const reducer = createReducer(initialState, {
  [fetchProducts.pending.type]: state => {
    state.productsData.loading = true;
  },
  [fetchProducts.fulfilled.type]: (state, action: PayloadAction<Product[]>) => {
    state.productsData.data = [...action.payload];
    state.productsData.loading = false;
    state.productsData.loaded = true;
    state.isFullyLoaded = true;
  },
  [fetchProducts.rejected.type]: (
    state,
    action: PayloadAction<null, string, unknown, SerializedError>
  ) => {
    state.productsData.error = action.error.message || "General Error";
    state.productsData.loading = false;
  },
  
  [fetchProductsByCategory.pending.type]: state => {
    state.productsData.loading = true;
    state.productsData.loaded = false
  },
  [fetchProductsByCategory.fulfilled.type]: (state, action: PayloadAction<Product[]>) => {
    state.productsData.data = (() => {
      if(state.isFullyLoaded){
        return state.productsData.data
      } else {
        const noDuplicates = action.payload.filter(n => !state.productsData.data.some(o => o.id == n.id))
        return [...state.productsData.data, ...noDuplicates]
      }
    })()
    state.productsData.loading = false;
    state.productsData.loaded = true;
  },
  [fetchProductsByCategory.rejected.type]: ( state, action: PayloadAction<null, string, unknown, SerializedError>) => {
    state.productsData.error = action.error.message || "General Error";
    state.productsData.loading = false;
  },

  [setActiveProduct.type]: (state, action: PayloadAction<Product>) => {
    state.activeProduct = action.payload;
  },
  
  [addRequestedCategory.type]: (state, { payload: categoryId }: PayloadAction<number>) => {
    state.requestedCategoryIds = [...state.requestedCategoryIds, categoryId]
  },

  [updateProduct.pending.type]: state => {
    state.productsData.loading = true;
    state.productsData.loaded = false;
  },

  [updateProduct.fulfilled.type]: (state, action: PayloadAction<Product[]>) => {
    const products = action.payload;

    products.map(product => {
      const indexOfProductInStore = state.productsData.data.findIndex(p => p.id === product.id);
      state.productsData.data = [
        ...state.productsData.data.slice(0, indexOfProductInStore),
        product,
        ...state.productsData.data.slice(indexOfProductInStore + 1),
      ];
    });
    state.productsData.loading = false;
    state.productsData.loaded = true;
  },

  [updateProduct.rejected.type]: (state, action) => {
    state.productsData.loading = false;
    state.productsData.loaded = true;
  },
});

// Selectors
const productsPageSelector = () =>
  createSelector(
    (state: ApplicationState) => state.products.productsData.data,
    (products: Product[]) => {
      const activeBrands = useAppSelector(state => state.UIfilters.activeBrands);
      const activeRating = useAppSelector(state => state.UIfilters.rating);

      if (activeBrands.length > 0) {
        products = products.filter((product: Product) => activeBrands.includes(product.brand?.id!));
      }

      if (activeRating !== 0) {
        products = products.filter((product: Product) => product.rating === activeRating);
      }

      return products;
    }
  );

const getActiveProductSelector = () =>
  createSelector(
    (state: ApplicationState) => state.products.activeProduct,
    (product: Product | null) => {
      const activeProductId = useAppSelector(state => state.router.location.pathname)
        .split("/")
        .pop();

      product = useAppSelector(state =>
        state.products.productsData.data.find(product => product.catalogNumber === activeProductId)
      )!;

      return product;
    }
  );

const selectors = {
  productsPageSelector,
  getActiveProductSelector,
};

const actions = {
  fetchProducts,
  fetchProductsByCategory,
  setActiveProduct,
  addRequestedCategory,
  updateProduct,
};

const productsStore = {
  actions,
  reducer,
  selectors,
  initialState,
};

export default productsStore;
export type { State };
