import { buildCategory, buildMediaUrl, buildProduct } from "../../utils/helper";
import { Category } from "../categories/types";
import { Product } from "../products/types";
import strapiService from "../strapi";
import { TMediaData, TSiteConfig } from "./types";

const getSiteConfig = async (): Promise<TSiteConfig> => {
  const fetchedData = await strapiService.fetchSiteConfig();
  // console.log("fetchedData ===>", fetchedData);
  const siteConfig: TSiteConfig = {
    // products: fetchedData.products?.map((p: { product: Product; }) => ({
    //   product: buildProduct(p.product),
    // })),
    runningText: fetchedData.runningText,
    carousel: fetchedData.carousel?.map((dto: TMediaData) => ({
      id: dto.id,
      url: buildMediaUrl(dto.url),
      caption: dto.caption,
    })),
    carouselMobile: fetchedData.carouselMobile?.map((dto: TMediaData) => ({
      id: dto.id,
      url: buildMediaUrl(dto.url),
      caption: dto.caption,
    })),
    catalogCarousel: fetchedData.catalogCarousel?.map((dto: TMediaData) => ({
      id: dto.id,
      url: buildMediaUrl(dto.url),
      caption: dto.caption,
    })),
    catalogCarouselMobile: fetchedData.catalogCarouselMobile?.map((dto: TMediaData) => ({
      id: dto.id,
      url: buildMediaUrl(dto.url),
      caption: dto.caption,
    })),
    middleBanner: !!fetchedData.middleBanner
      ? {
          id: fetchedData.middleBanner.id,
          url: buildMediaUrl(fetchedData.middleBanner.url),
          caption: fetchedData.middleBanner.caption,
        }
      : null,
    middleBannerMobile: !!fetchedData.middleBannerMobile
      ? {
          id: fetchedData.middleBannerMobile.id,
          url: buildMediaUrl(fetchedData.middleBannerMobile.url),
          caption: fetchedData.middleBannerMobile.caption,
        }
      : null,
    bottomBanner: !!fetchedData.bottomBanner
      ? {
          id: fetchedData.bottomBanner.id,
          url: buildMediaUrl(fetchedData.bottomBanner.url),
          caption: fetchedData.bottomBanner.caption,
        }
      : null,
    contactUs: !!fetchedData.contactUs
      ? {
          address: fetchedData.contactUs.address,
          fax: fetchedData.contactUs.fax,
          phone: fetchedData.contactUs.phone,
          email: fetchedData.contactUs.email,
        }
      : null,
    posts: fetchedData.posts?.map((post: TMediaData) => ({
      id: post.id!,
      url: post.url!,
      caption: post.caption!,
    })),
    homePageProduct: !!fetchedData.homePageProduct
      ? buildProduct(fetchedData.homePageProduct)
      : null,
    socialMedia: !!fetchedData.socialMedia
      ? {
          facebook: fetchedData.socialMedia?.facebook!,
          twitter: fetchedData.socialMedia?.twitter!,
          pinterest: fetchedData.socialMedia?.pinterest!,
          linkedin: fetchedData.socialMedia?.linkedin!,
        }
      : null,
    categories: fetchedData.categories?.map((c: { category: Category; name: any }) => ({
      category: buildCategory(
        c.category,
        fetchedData?.categories?.map((c: { category: any }) => c.category)
      )!,
      name: c?.name!,
    })),
    maxOrderPrice: fetchedData?.maxOrderPrice!,
    maxOrderWeight: fetchedData?.maxOrderWeight!,
    TermsAndConditions: fetchedData?.TermsAndConditions!,
    TermsAndConditionsHebrew: fetchedData?.TermsAndConditionsHebrew!,
    BuyPolicy: fetchedData?.BuyPolicy!,
    BuyPolicyHebrew: fetchedData?.BuyPolicyHebrew!,
    privacyPolicy: fetchedData.privacyPolicy!,
    privacyPolicyHebrew: fetchedData.privacyPolicyHebrew!,
    accessibility: fetchedData.accessibility!,
    accessibilityHebrew: fetchedData.accessibilityHebrew!,
    CustomerService: fetchedData?.CustomerService,
    CustomerServiceHebrew: fetchedData?.CustomerServiceHebrew,
    vat: fetchedData?.vat!,
    shipByPostPrice: fetchedData?.shipByPostPrice!,
    courierPrice: fetchedData?.courierPrice!,
    careers: fetchedData?.careers,
    navigationLinks: fetchedData?.navigationLinks,
    title: fetchedData.title,
    description: fetchedData.description,
    keywords: fetchedData.keywords,
  };

  return siteConfig;
};

export { getSiteConfig };
