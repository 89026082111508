import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(
  theme => ({
    root: {
      top: 0,
      position: "fixed",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      zIndex: 6,
    },
    title: {
      zIndex: 1,
      position: "relative",
      width: "100%",
      backgroundColor: theme.colors.green,
      display: "flex",
      flexDirection: "column",
      padding: "5px 0",
      [theme.device.mobile()]: {
        padding: "7px 0",
      },
    },

    "@keyframes motion": {
      from: { transform: "translateX(-100%)" },
      to: { transform: "translateX(100%)" },
    },

    text: {
      fontSize: 18,
      color: theme.colors.text,
      width: "fit-content",
      margin: "0 auto",
      whiteSpace: "nowrap",
    },

    textIsMoving: {
      animation: "$motion 25s linear infinite",
    },

    logo: {
      userSelect: "none",
      alignSelf: "flex-start",
      marginLeft: 100,
      position: "absolute",
      top: 30,
      width: 120,
      height: 170,
      transition: "0.5s",
      [theme.device.tablet()]: {
        marginLeft: 55,
        width: 90,
        height: 120,
      },
      [theme.device.smallTablet()]: {
        marginLeft: 45,
        width: 67,
        height: 80,
      },

      [theme.device.mobile()]: {
        marginLeft: 15,
        top: 37,
        width: 47,
        height: 60,
      },
    },

    logoScrolled: {
      transition: "0.5s",

      [theme.device.desktop()]: {
        width: 85,
        height: 120,
      },
    },
    logoImg: {
      cursor: "pointer",
      width: "100%",
      height: "100%",
    },
    content: {
      position: "relative",
      backgroundColor: theme.colors.text,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 40,
      [theme.device.mobile()]: {
        justifyContent: "center",
        height: 50,
      },
    },
    language: {
      userSelect: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      cursor: "pointer",
      boxSizing: "border-box",
      position: "relative",
      height: "100%",
      minWidth: 70,
      marginRight: 150,
      [theme.device.laptop()]: {
        marginRight: 70,
      },
      [theme.device.tablet()]: {
        marginRight: 60,
      },
      [theme.device.smallTablet()]: {
        marginRight: 60,
      },
      [theme.device.mobile()]: {
        marginRight: 60,
        minWidth: 100,
      },
    },
    currentLanguage: {
      position: "relative",
      padding: "3px 0",
      marginRight: 11,
    },

    languageItem: {
      gridColumnGap: 2,
      padding: 3,
      minWidth: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    languageName: {
      color: theme.colors.green,
      fontWeight: 700,
      fontSize: 19,
      "&:first-letter": {
        textTransform: "capitalize",
      },
    },
    languageIcon: {
      width: 41,
      height: 34,
    },
    chooseLanguage: {
      transition: "0.3s",
      opacity: 0,
      visibility: "hidden",
      zIndex: -1,
      background: theme.colors.text,
      transform: "translateY(-100%)",
      position: "absolute",
      top: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gridColumnGap: 3,
      alignItems: "center",
    },
    panel: {
      marginLeft: 270,
      width: "8%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      [theme.device.smallDesktop()]: {
        marginLeft: 270,
        width: "8%",
      },
      [theme.device.laptop()]: {
        marginLeft: 260,
        width: "10%",
      },
      [theme.device.tablet()]: {
        marginLeft: 180,
        width: "11%",
      },
      [theme.device.smallTablet()]: {
        marginLeft: 140,
        width: "13%",
      },
      [theme.device.mobile()]: {
        paddingLeft: 60,
        gridColumnGap: 20,
        marginLeft: 0,
        boxSizing: "border-box",
        // paddingLeft: 100,
        width: "100%",
        justifyContent: "space-evenly",
      },
      [theme.device.smallMobile()]: {
        marginLeft: 0,
        boxSizing: "border-box",
        paddingLeft: 90,
        width: "100%",
        justifyContent: "flex-start",
        gridColumnGap: 10,
      },
    },
    dropMenu: {
      visibility: "hidden",
      opacity: 0,
      transition: "0.3s",
      height: "100%",
      position: "absolute",
      top: 0,
      right: 0,
      width: 250,
      [theme.device.mobile()]: {
        width: "77%",
      },
    },
    dropMenuActive: {
      visibility: "visible",
      opacity: 1,
    },
    dropMenuItem: {
      userSelect: "none",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: theme.colors.text,
      border: `1px solid ${theme.colors.text}`,
      height: "100%",
      backgroundColor: theme.colors.green,
      "&:first-child:hover": {
        cursor: "default",
        backgroundColor: theme.colors.green,
        border: `1px solid ${theme.colors.text}`,
        color: theme.colors.text,
      },
      "&:hover": {
        backgroundColor: theme.colors.text,
        border: `1px solid ${theme.colors.green}`,
        color: theme.colors.green,
      },
    },
    dropMenuAuth: {
      display: theme.device.isBrowser ? "none" : "flex",
      backgroundColor: theme.colors.yellow,
      fontWeight: 700,
    },
    menu: {
      zIndex: 1,
      position: "absolute",
      right: 0,
      marginRight: 12,
      display: "flex",
      padding: "5px 0",
    },
    authPopup: {
      position: "absolute",
      margin: "0 auto",
      top: "100%",
      visibility: "hidden",
      opacity: 0,
      transition: "0.3s",
      [theme.device.mobile()]: {
        display: "none",
      },
    },
    menuIcon: {
      cursor: "pointer",
      height: 35,
      width: 35,
    },
    menuIconActive: {
      ...theme.utils.svgStroke(theme.colors.text),
    },
    icon: {
      cursor: "pointer",
      height: 30,
      width: 30,
    },
    searchIcon: {
      marginTop: 3,
      height: 27,
      width: 27,
    },
    authWrapper: {
      position: "absolute",
      left: "45%",
      ["@media (min-width: 2000px)"]: {
        left: "46%",
      },
      ["@media (max-width: 1800px)"]: {
        left: "46%",
      },
      ["@media (max-width: 1700px)"]: {
        left: "45%",
      },
      [theme.device.smallDesktop()]: {
        left: "43%",
      },
      [theme.device.laptop()]: {
        left: "42%",
      },
      [theme.device.smallTablet()]: {
        display: "none",
      },
    },
    auth: {
      cursor: "pointer",
      userSelect: "none",
      alignSelf: "flex-end",
      color: theme.colors.green,
      borderBottom: "2px solid transparent",
      transition: "border-bottom 0.2s",
      fontWeight: "normal",
      fontSize: 18,
      paddingBottom: 2,
      "&:hover": {
        borderBottom: `2px solid ${theme.colors.yellow}`,
      },
      [theme.device.mobile()]: {
        display: "none",
      },
    },
    cart: {
      userSelect: "none",
      cursor: "pointer",
      position: "relative",
      height: 30,
    },
    cartCount: {
      position: "absolute",
      top: "60%",
      right: 0,
      backgroundColor: theme.colors.green,
      borderRadius: "100%",
      color: theme.colors.text,
      textAlign: "center",
      fontSize: 9,
      padding: "2px 4px",
    },
    activeLanguage: {
      visibility: "visible",
      opacity: 1,
      transition: "0.3s",
      transform: "translateY(0)",
      height: "auto",
      zIndex: 0,
    },
    active: {
      visibility: "visible",
      opacity: 1,
      transition: "0.3s",
    },
    activeDesktop: {
      [theme.device.mobile()]: {
        display: "none",
      },
    },
  }),
  { name: "header" }
);
