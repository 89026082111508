import { useTheme } from "@material-ui/styles";
import React, { useEffect, useRef, useState } from "react";
import { push } from "connected-react-router";
import clsx from "clsx";

import SearchWindow from "./search-window";
import Arrow from "../common/arrow";
import Cart from "./cart/cart";

import languagesStore, { Language } from "../../store/languages-store";
import { useAppDispatch, useAppSelector } from "../../store/store";
import authFormStore from "../../store/auth-form-store";
import { NewPhrase, NewPhrases } from "../../utils/language";
import useAuth from "../../hooks/useAuth";
import { config } from "../../config";

import { ReactComponent as IsraelFlag } from "../../static/icons/israel-flag.svg";
import { ReactComponent as MenuIcon } from "../../static/icons/drop-menu.svg";
import { ReactComponent as ArabicFlag } from "../../static/icons/arabic.svg";
import { ReactComponent as SearchIcon } from "../../static/icons/search.svg";
import { ReactComponent as CartIcon } from "../../static/icons/bag.svg";
import logo from "../../static/images/bambinoHighRes.png";
import { useStyles } from "./header-styles";
import useViewport from "../../hooks/useViewport";
import Auth from "../authentication/auth";
import { isMobile } from "react-device-detect";

// const getPahrase = (name: string): NewPhrase => {
//   switch(name) {
//     case "my_account": return "my_account";
//     case "buy_policy": return "buy_policy";
//     case "costumer_service": return "costumer_service";
//     case "careers": return "careers";
//     case "product_catalog": return "product_catalog";
//     case "authorized_resellers": return "authorized_resellers";
//     case "importer_deals": return "importer_deals";
//     // case "car_suitability": return "car_suitability";
//     default: return "my_account";
//   }
// }

const getMenuData = (lang: Language): { name: NewPhrase; path: string }[] => [
  {
    name: " ",
    path: "",
  },

  // {
  //   name: "my_account",
  //   path: config.routes.myAccount,
  // },
  // {
  //   name: "buy_policy",
  //   path: config.routes.buyPolicy,
  // },
  // {
  //   name: "costumer_service",
  //   path: config.routes.customerService,
  // },
  // {
  //   name: "careers",
  //   path: config.routes.careers,
  // },
  // {
  //   name: "product_catalog",
  //   path: config.routes.catalogPage,
  // },
  // {
  //   name: "authorized_resellers",
  //   path: config.routes.approvedSellers,
  // },
  // {
  //   name: "importer_deals",
  //   path: config.routes.importerDeals,
  // },
  // { // TODO: Uncomment when starting implementation
  //   name: getPhrase("car_suitability", lang),
  //   path: "",
  // },
];

const getLanguageItems = (iconClass: string): { name: Language; icon: JSX.Element }[] => [
  {
    name: "he",
    icon: <IsraelFlag />,
  },
  {
    name: "ar",
    icon: <ArabicFlag />,
  },
];

const Header = (): JSX.Element => {
  const { navigationLinks } = useAppSelector(state => ({
    navigationLinks: state.siteConfig.navigationLinks,
  }));

  const [isMoving, setIsMoving] = useState<boolean>(false);
  const textRef = useRef<HTMLSpanElement>(null);

  const {
    authFormIsVisible,
    langDropDownIsVisible,
    cartIsVisible,
    hamburgerMenuIsVisble,
    searchIsVisible,
  } = useAppSelector(
    ({
      authForm: {
        authFormIsVisible,
        langDropDownIsVisible,
        cartIsVisible,
        hamburgerMenuIsVisble,
        searchIsVisible,
      },
    }) => ({
      authFormIsVisible,
      langDropDownIsVisible,
      cartIsVisible,
      hamburgerMenuIsVisble,
      searchIsVisible,
    })
  );

  const { isAuthFormVisible, totalCartItemsCount, userId, lang, phrases, user, loaded } =
    useAppSelector(state => {
      let totalCartItemsCount = 0;
      state.user.data?.cartItems
        ?.filter(item => state.products.productsData.data.find(p => p.id === item.productId))
        ?.forEach(cartItem => (totalCartItemsCount += cartItem.productCount));

      return {
        totalCartItemsCount,
        isAuthFormVisible: state.authForm.authFormIsVisible,
        lang: state.languages.language,
        phrases: state.languages.phrases,
        user: state.user.data,
        loaded: state.user.loaded,
        userId: state.user.data?.id,
      };
    });

  const { runningText } = useAppSelector(state => state.siteConfig);

  const activePhrases: NewPhrases | undefined = useAppSelector(
    languagesStore.selectors.getActivePhrases()
  );

  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  const handleScroll = () => {
    const distannceY = window.pageYOffset || document.documentElement.scrollTop;
    const shrinkOn = 150;

    if (distannceY > shrinkOn) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    }
  }, []);

  const { isAuth } = useAuth(userId);
  const { width } = useViewport();

  const dispatch = useAppDispatch();

  const classes = useStyles();

  const theme = useTheme();

  const languages = getLanguageItems(classes.languageIcon);
  const currentLanguage = languages.find(language => language.name === lang);

  const toggleMenu = () =>
    dispatch(
      authFormStore.actions.toggleVisibility("hamburgerMenuIsVisble", !hamburgerMenuIsVisble)
    );

  const onLogoClick = () => {
    dispatch(push(config.routes.homePage, true));
  };

  const onMenuItemClick = (path: string) => {
    dispatch(push(path, true));
    dispatch(
      authFormStore.actions.toggleVisibility("hamburgerMenuIsVisble", !hamburgerMenuIsVisble)
    );
  };

  const navLink = [{ name: "", url: "" }, ...navigationLinks];
  const menuItems = navLink.map((item, i) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const label = activePhrases && activePhrases[item.name];
    return (
      <div
        key={item.name}
        className={classes.dropMenuItem}
        onClick={() => onMenuItemClick(item.url)}
      >
        {label}
      </div>
    );
  });

  const onSearchClick = () =>
    dispatch(authFormStore.actions.toggleVisibility("searchIsVisible", !searchIsVisible));

  const onCartClick = () => {
    if (!isAuth && isMobile) {
      return dispatch(push("/authentication"));
    }
    if (isAuth) dispatch(authFormStore.actions.toggleVisibility("cartIsVisible", !cartIsVisible));
    else dispatch(authFormStore.actions.toggleVisibility("authFormIsVisible", true));
  };

  const onAuthClick = () => {
    dispatch(authFormStore.actions.toggleVisibility("authFormIsVisible", !authFormIsVisible));
  };

  const onLanguageBarClick = () =>
    dispatch(
      authFormStore.actions.toggleVisibility("langDropDownIsVisible", !langDropDownIsVisible)
    );

  const onLanguageItemClick = (language: Language) => () => {
    dispatch(languagesStore.actions.changeLanguage(language));
    dispatch(
      authFormStore.actions.toggleVisibility("langDropDownIsVisible", !langDropDownIsVisible)
    );
  };

  useEffect(() => {
    const textWidth = textRef.current?.clientWidth;

    if (textWidth && width && textWidth > width - 20) {
      setIsMoving(true);
    } else if (textWidth && width && textWidth < width) {
      setIsMoving(false);
    }
  }, [width, runningText, textRef]);

  return (
    <div className={classes.root}>
      <Auth
        className={clsx(
          classes.authPopup,
          authFormIsVisible && classes.active,
          classes.activeDesktop
        )}
      />
      <div className={classes.title}>
        <div className={clsx(classes.logo, isScrolled && classes.logoScrolled)}>
          <img className={classes.logoImg} src={logo} alt="logo" onClick={onLogoClick} />
        </div>
        <span className={clsx(classes.text, isMoving && classes.textIsMoving)} ref={textRef}>
          {runningText}
        </span>
      </div>

      <div className={classes.content}>
        <div className={classes.panel}>
          <SearchIcon className={clsx(classes.icon, classes.searchIcon)} onClick={onSearchClick} />
          <div className={classes.cart} onClick={onCartClick}>
            <CartIcon className={classes.icon} />
            {isAuth && totalCartItemsCount > 0 && (
              <div className={classes.cartCount}>{totalCartItemsCount}</div>
            )}
          </div>
        </div>
        <div className={classes.authWrapper}>
          {loaded && !user && (
            <div className={classes.auth} onClick={onAuthClick}>
              {activePhrases && activePhrases["login"]}/
              {activePhrases && activePhrases["registration"]}
            </div>
          )}
        </div>

        {/* <div className={classes.language}>
          <div
            className={clsx(classes.languageItem, classes.currentLanguage)}
            onClick={onLanguageBarClick}
          >
            <Arrow color={theme.colors.green} borderWidth={6} active={langDropDownIsVisible} />

            {currentLanguage?.icon}

            <div className={classes.languageName}>{currentLanguage?.name}</div>
          </div>

          <div
            className={clsx(
              classes.chooseLanguage,
              langDropDownIsVisible && classes.activeLanguage
            )}
          >
            {languages
              .filter(language => language.name !== lang)
              .map((language, i) => (
                <div
                  key={i}
                  className={classes.languageItem}
                  onClick={onLanguageItemClick(language.name)}
                >
                  {language.icon}
                  <div className={classes.languageName}>{language.name}</div>
                </div>
              ))}
          </div>
        </div> */}


        <div className={classes.menu}>
          <MenuIcon
            className={clsx(classes.menuIcon, hamburgerMenuIsVisble && classes.menuIconActive)}
            onClick={toggleMenu}
          />
        </div>

        <div className={clsx(classes.dropMenu, hamburgerMenuIsVisble && classes.dropMenuActive)}>
          {menuItems}
        </div>

        <SearchWindow className={{ root: clsx(searchIsVisible && classes.active) }} />

        <Cart className={{ root: clsx(cartIsVisible && classes.active) }} />
      </div>
    </div>
  );
};

export default Header;
