import { ThemeProvider, DefaultTheme, ClassNameMap } from "@material-ui/styles";
import React, { CSSProperties, PropsWithChildren } from "react";
import { isBrowser, isMobile } from "react-device-detect";

export type TTypedStyles<TStyles> = (props: TStyles) => ClassNameMap<string>;

declare module "@material-ui/styles/defaultTheme" {
  interface DefaultTheme {
    colors: {
      white: React.CSSProperties["color"];
      gray: React.CSSProperties["color"];
      green: React.CSSProperties["color"];
      red: React.CSSProperties["color"];
      logo: React.CSSProperties["color"];
      darkBlue: React.CSSProperties["color"];
      black: React.CSSProperties["color"];
      yellow: React.CSSProperties["color"];
      text: React.CSSProperties["color"];
      lightGreen: React.CSSProperties["color"];
      border: React.CSSProperties["color"];
      scroll: React.CSSProperties["color"];
      shadow: React.CSSProperties["color"];
      darkText: React.CSSProperties["color"];
      grayText: React.CSSProperties["color"];
      overlay: React.CSSProperties["color"];
      overlayDark: React.CSSProperties["color"];
      overlayLight: React.CSSProperties["color"];
      disabled: React.CSSProperties["color"];
      grayBorder: React.CSSProperties["color"];
      activeTab: React.CSSProperties["color"];
      whiteBackground: React.CSSProperties["color"];
      blue: React.CSSProperties["color"];
    };
    utils: {
      svgStroke: (color: CSSProperties["color"]) => object;
      svgFill: (color: CSSProperties["color"]) => object;
      svgChangeColor: (color: CSSProperties["color"]) => object;
      boxShadow: (boxShadow: CSSProperties["boxShadow"]) => string;
    };
    device: {
      desktop: () => string;
      smallDesktop: () => string;
      laptop: () => string;
      tablet: () => string;
      smallTablet: () => string;
      mobile: () => string;
      smallMobile: () => string;
      isMobile: boolean;
      isBrowser: boolean;
    };
  }
}

const theme: DefaultTheme = {
  colors: {
    white: "ffffff",
    black: "#000000",
    gray: "#e7ecec",
    darkBlue: "#005BB8",
    green: "#114649",
    red: "#FF0033",
    yellow: "#d7942b",
    logo: "#114749",
    text: "#ffffff",
    darkText: "#575757",
    grayText: "#8a8a8a",
    scroll: "#212121",
    shadow: "rgba(0,0,0,0.32)",
    lightGreen: "#749495",
    border: "#c6c6c6",
    overlay: "rgba(37, 75, 78, 0.4)",
    overlayDark: "rgba(22, 25, 26, 0.5)",
    overlayLight: "rgba(42, 47, 49, 0.5)",
    disabled: "#80707070",
    grayBorder: "#707070",
    activeTab: "#B57E2B",
    whiteBackground: "#f2f2f2",
    blue: "#457EC2",
  },
  device: {
    desktop: (): string => "@media (min-width: 600px)",
    smallDesktop: (): string => "@media (max-width: 1400px)",
    laptop: (): string => "@media (max-width: 1281px)",
    tablet: (): string => "@media (max-width: 1024px)",
    smallTablet: (): string => "@media (max-width: 801px)",
    // smallMobile: (): string => "@media (max-width: 340px)",
    mobile: (): string => "@media (max-width: 600px)",
    smallMobile: (): string => "@media (max-width: 350px)",
    isMobile,
    isBrowser,
  },
  utils: {
    svgStroke: (color: CSSProperties["color"]): object => ({
      "& polyline, line, circle, g, rect, polygon, path": {
        stroke: color,
      },
    }),
    svgFill: (color: CSSProperties["color"]): object => ({
      "& polyline, line, circle, g, rect, polygon, path": {
        fill: color,
      },
    }),
    svgChangeColor: (color: CSSProperties["color"]): object => ({
      "& polyline, line, circle, rect, polygon, path": {
        fill: color,
        stroke: color,
      },
    }),
    boxShadow: (): string => "0px 3px 3px #000000AB",
  },
};

const Theme = ({ children }: PropsWithChildren<unknown>): JSX.Element => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;

export { theme };
