import { CategoryLevel } from "./services/categories/types";

const routes = {
  importerDealsCatalog: "/importer-deals/catalog",
  importerDealsProduct: "/importer-deals/product",
  importerDealsPage: "/importer-deals/categories",
  termsAndConditions: "/terms-and-conditions",
  privacyPolicy: "/privacy-policy",
  accessibility: '/accessibility',
  importerDeals: "/importer-deals/categories",
  favoritesList: "/my-account/favorites-list",
  ordersHistory: "/my-account/order-history",
  redirect: "/auth/:providerName/redirect",
  customerService: "/customer-service",
  productsPage: "/products/categories",
  approvedSellers: "/approved-sellers",
  shoppingBagPage: "/shopping-bag",
  thanksPage: "/thanks-pages",
  thanksPagePhone: "/thanks-pages?phone",
  thanksPageCard: "/thanks-pages?card",
  thanksPageSuccBit: "/thanks-pages?successbit",
  thanksPageFailBit: "/thanks-pages?failbit",
  product: "/products/product",
  authPage: "/authentication",
  checkoutPage: "/checkout",
  myAccount: "/my-account",
  buyPolicy: "/buy-policy",
  careers: "/careers",
  positions: "/positions",
  catalogPage: "/catalog",
  contact: "/contact",
  mapsPage: "/maps",
  homePage: "/",
};

const endpoints = {
  paymentSystem: "https://direct.tranzila.com/bambbino/iframenew.php",
  updateProduct: (catalogNumber: string) => `/products/${catalogNumber}`,
  updateUser: (id: number) => `/users/${id}`,
  authRegister: "/auth/local/register",
  approvedSellers: "/approved-sellers",
  addSubscriptionEmail: "/newsletters",
  authReset: "/auth/reset-password",
  getLanguages: "/languages",
  categories: "/categories",
  authLogin: "/auth/local",
  currentUser: "/users/me",
  siteConfig: "/bambino",
  products: "/products?isPublished=true&_limit=-1",
  allProducts: "/allProducts?_limit=-1",
  getCategoryProducts: (categoryLevel: CategoryLevel, categoryId: number) => 
    `/productsByCategory?${categoryLevel}=${categoryId}`,
  brands: "/brands",
  orders: "/orders?_limit=-1",
  updateOrder: "/orders",
  users: "/users/",
  jobApplications: "/job-applications",
  upload: "/upload",
  thanksPages: "/thanks-pages",
};

export const config = {
  routes,
  endpoints,
};
