import { Phrases } from "./utils/language";

const phrases: Phrases = {
  "": "General Error",
  search: "search",
  auth: "log in / registration",
  shopping_bag: "Shopping bag",
  sign_up: "Sign up",
  login: "Log in",
  features: "Features",
  free_shipping: "Free shipping",
  qty: "Qty",
  add_to_cart: "Add to cart",
  view_more: "View more",
  load_more: "Load more",
  join_subscription: "Join our subscription",
  join_newsletter: "Join the monthly newsletter",
  email: "E-mail",
  submit: "Submit",
  cancel: "Cancel",
  filters: "Filters",
  sort_by: "Sort by",
  my_account: "My account",
  buy_policy: "Buy policy",
  customer_service: "Customer Service",
  career: "Career",
  accumulative_cart: "Accumulative cart",
  product_catalog: "Product Catalog",
  authorized_resellers: "Authorized resellers",
  importer_deals: "Importer deals",
  car_suitability: "Car suitability questionnaire",
  search_guides: "Search guides",
  privacy_policy: "Privacy Policy",
  terms_of_use: "Terms of Use",
  age: "Age",
  size: "Size",
  weight: "Weight",
  color: "Color",
  delivery_time: 'delivery time',
  warranty: "Warranty",
  available_colors: "Available colors",
  share: "Share",
  newest: "Newest",
  alphabet: "Alphabet",
  popularity: "Popularity",
  cheapest: "Cheapest",
  expensive: "Expensive",
  additional_info: "Additional info",
  all_categories: "All Categories",
  google_login: "Log in with Google",
  google_sign_up: "Sign up with Google",
  empty_bag: "Empty bag",
  shipping: "Shipping",
  subtotal: "Subtotal",
  password: "Password",
  verify_password: "Verify Password",
  full_address: "Full Address",
  first_name: "First Name",
  last_name: "Last Name",
  phone_number: "Phone Number",
  manufacturies: "Manufacturies",
  rate: "Rate",
  show_more: "Show more",
  similar_products: "Similar products",
  related_products: "Related products",
  description: "Description",
  specification: "Technical Specification",
  kit: "What is included in the kit",
  qna: "Q&A",
  reviews: "Reviews",
  id: "id",
  view_bag: "View bag",
  proceed_to_checkout: "Proceed to checkout",
  item: "item",
  items: "items",
  order_summary: "Order summary",
  order_total: "Order total",
  subtotal_before_delivery: "Subtotal before delivery charges",
  include_vat: "incl. VAT",
  mobile_phone: "Mobile Phone",
  checkout: "Checkout",
  shipping_address: "Shipping address",
  billing_address: "Billing address",
  different_address: "Use different address",
  full_name: "Full Name",
  notes: "Notes",
  shipping_method: "Shipping method",
  express_courier: "Express Courier (Air)",
  up_to_business: "Up to ? business day(s)",
  payment: "Payment",
  payment_method: "Please choose your payment method",
  card_number: "Card number",
  expiry_date: "Expiry date",
  security_code: "Security code",
  place_order: "Pay and place order",
  january: "January",
  february: "February",
  march: "March",
  april: "April",
  may: "May",
  june: "June",
  july: "July",
  august: "August",
  september: "September",
  october: "October",
  november: "November",
  december: "December",
  personal_details: "Personal details",
  order_history: "Order History",
  favorites_list: "Favorites List",
  sign_out: "Sign Out",
  catalog_number: "Catalog number",
  more_details: "More Details",
  add_all_to_cart: "Add All to Cart",
  no_items_added_yet: "No items added yet",
  welcome: "Welcome",
  my_id: "ID",
  consumer_price: "Consumer Price",
  savings: "Savings",
  sale_price: "Sale Price",
  discount_date: "Valid until",
  facebook_login: "Log in with Facebook",
  facebook_sign_up: "Sign up with Facebook",
  "Please provide your username or your e-mail": "Please provide your username or your e-mail",
  "Please provide your password": "Please provide your password",
  "Please provide your new password": "Please provide your new password",
  "Please provide your new password confirmation": "Please provide your new password confirmation",
  "New Passwords do not match": "New Passwords do not match",
  "Current password is invalid": "Current password is invalid",
  name: "Name",
  second_phone_number: "Second phone number",
  address: "Address",
  change_password: "Change password",
  change_your_password: "Change your password",
  current_password: "Current password",
  new_password: "New password",
  confirm_new_password: "Confirm new password",
  min_pwd_chars: "Minimum of 6 characters, no spaces",
  save_changes: "Save changes",
  contact_us: "Contact us",
  required_field: "Field is Required",
  invalid_email: "Invalid e-mail",
  topic: "Topic",
  message: "Message",
  phone: "Phone",
  send_message: "Send message",
  send_message_success: "Your message was successfuly sent!",
  terms_and_conditions: "Terms and Conditions",
  something_went_wrong: "Something went wrong, please refresh your browser",
  tc_agree: "I read and agree to Bambino King's Terms and Conditions and Privacy Policy.",
  out_of_stock: "Out of stock",
  available_on: "Available on",
  approved_sellers: "Approved Sellers",
  all: "All",
  north: "North",
  center: "Center",
  south: "South",
  free: "Free",
  pick_up: "Pick up",
  ship_by_post: "Ship by post",
  catalog_n: "Catalog n.",
  order_n: "Order n.",
  less_details: "Less details",
  order_date: "Order date",
  status: "Status",
  update_message: "You have successfully updated your account information",
  store_price: "Store price",
  vat: "VAT",
  warning_message: "You can not exceed the available quantity of the product",
  date: "Date",
  has_subscription: "Email already subscribed",
  successful_subscription: "You have been subscribed",
  from: "From",
  to: "to",
  height: "Height",
  width: "width",
  length: "length",
  empty_cart: "Your cart is empty",
  notFound: "we couldn't find the page you are looking for",
  copy_link: "Copy link",
  min: "min",
  max: "max",
  price_range: "Range slider",
  years: "y",
  product_link: "You can buy it from the official website.",
  write_review: "Write Review",
  name_username: "Name/Username",
  close: "Close",
  review: "Review",
  main_address: "Address",
  accessibility: "Accessibility"
};

export { phrases };
