import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { push } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { config } from "../../config";
import { ReactComponent as CloseIcon } from "../../static/icons/close.svg";
import { ReactComponent as SearchIcon } from "../../static/icons/search.svg";
import authFormStore from "../../store/auth-form-store";
import categoriesStore from "../../store/categories-store";
import languagesStore from "../../store/languages-store";
import { useAppDispatch, useAppSelector } from "../../store/store";
import UIsortingStore from "../../store/ui-sorting-store";
import { checkImporterDeals } from "../../utils/helper";
import { useHistory } from "react-router";

type TProps = {
  className?: {
    root?: string;
    input?: string;
    icon?: string;
  };
};

const SearchWindow = ({ className }: TProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [value, setValue] = useState<string>("");
  const searchButtonIsDisabled = value.length < 2;

  const isImporterDeals = checkImporterDeals(
    useAppSelector(state => state.router.location.pathname)
  );

  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());
  const searchIsVisible = useAppSelector(state => state.authForm.searchIsVisible);
  const products = useAppSelector(state => state.products.productsData.data);
  let filteredProducts = null;

  const redirectToProduct = (productLink: string) => {
    history.push(productLink);
  };

  if (value) {
    filteredProducts = products.filter(
      p =>
        p?.name?.toLocaleLowerCase().startsWith(value.toLocaleLowerCase()) ||
        p?.title?.toLocaleLowerCase().startsWith(value.toLocaleLowerCase()) ||
        p?.keywords?.toLocaleLowerCase().startsWith(value.toLocaleLowerCase())
    );
  }

  useEffect(() => {
    setValue("");
  }, [searchIsVisible]);

  const onCloseBtnClick = () =>
    dispatch(authFormStore.actions.toggleVisibility("searchIsVisible", false));

  const handleSearch = () => {
    if (searchButtonIsDisabled) return;
    dispatch(categoriesStore.actions.setActiveCategory(null));
    dispatch(categoriesStore.actions.setActiveSubSubCategory(null));
    dispatch(UIsortingStore.actions.changeSorting("alphabet"));
    dispatch(
      push(
        (isImporterDeals ? config.routes.importerDeals : config.routes.productsPage) +
          `?search=${value}`
      )
    );
  };

  const handleKeyPress = (event: any) => {
    event.preventDefault();
    handleSearch();
    setValue("");
  };

  return (
    <form
      className={clsx(classes.root, className?.root)}
      autoComplete="off"
      onSubmit={event => {
        handleKeyPress(event);
        onCloseBtnClick();
      }}
    >
      <SearchIcon
        className={clsx(
          classes.icon,
          classes.searchIcon,
          !searchButtonIsDisabled && classes.searchButttonActive
        )}
        onClick={handleSearch}
      />
      <div>
        <input
          className={clsx(classes.input, className?.input)}
          value={value}
          type="text"
          name="search"
          placeholder={activePhrases && activePhrases["search"]}
          onChange={event => setValue(event.target.value)}
        />
        <SearchIcon
          className={clsx(
            classes.searchButton,
            !searchButtonIsDisabled && classes.searchButttonActive
          )}
          onClick={handleSearch}
        />
        <CloseIcon className={clsx(classes.icon, className?.icon)} onClick={onCloseBtnClick} />
        {filteredProducts ? (
          <ul className={classes.dropdown}>
            {filteredProducts.map(r => (
              <li
                className={classes.dropdownItem}
                onClick={() => {redirectToProduct(`${config.routes.product}/${r.catalogNumber}`); onCloseBtnClick()}}
              >
                <span>{r.name + " " + r.title}</span>
                <img src={r.mainPicture.url} width="50" height="50" />
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </form>
  );
};

export default SearchWindow;

const useStyles = makeStyles(
  theme => ({
    root: {
      visibility: "hidden",
      opacity: 0,
      borderRadius: "5px",
      transition: "0.3s",
      position: "absolute",
      padding: "5px 7px",
      backgroundColor: theme.colors.green,
      top: "100%",
      left: 218.5,
      display: "flex",
      alignItems: "center",
      [theme.device.mobile()]: {
        padding: 6,
        left: 0,
        boxSizing: "border-box",
        display: "grid",
        gridTemplateColumns: "40px 2fr 40px",
      },
    },
    input: {
      width: 250,
      border: "none",
      outline: "none",
      textAlign: "right",
      boxSizing: "border-box",
      padding: "7px 18px",
      borderRadius: 12,
      fontSize: 16,
      color: theme.colors.green,
      "&::placeholder": {
        userSelect: "none",
        color: theme.colors.lightGreen,
      },
      [theme.device.mobile()]: {
        borderRadius: 14,
        fontSize: 22,
        margin: "0 auto",
        width: "90%",
      },
    },
    dropdown: {
      width: "100%",
      maxHeight: "260px",
      border: "2px",
      padding: "2px",
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
        width: "0.4rem",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px darkgrey",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#114649",
        outline: "1px solid slategrey",
      },
    },
    dropdownItem: {
      display: "flex",
      marginBottom: "2px",
      alignItems: "center",
      justifyContent: "end",
      color: "white",
      gap: "5px",
      cursor: "pointer",
    },
    icon: {
      marginLeft: 5,
      cursor: "pointer",
      alignSelf: "flex-start",
      width: 15,
      height: 15,
      ...theme.utils.svgStroke(theme.colors.text),
      [theme.device.mobile()]: {
        alignSelf: "center",
        margin: "0 auto",
        height: 22,
        width: 22,
      },
    },
    searchIcon: {
      ...theme.utils.svgStroke(theme.colors.grayText),
      height: 27,
      width: 27,
      [theme.device.desktop()]: {
        display: "none",
      },
    },

    searchButton: {
      width: 30,
      height: 30,
      cursor: "pointer",
      margin: "0 10px",
      ...theme.utils.svgStroke(theme.colors.grayText),

      [theme.device.mobile()]: {
        display: "none",
      },
    },

    searchButttonActive: {
      ...theme.utils.svgStroke(theme.colors.text),
    },
  }),
  { name: "search-window" }
);
