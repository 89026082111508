import React, { useState, useEffect, lazy, Suspense } from "react";
import { makeStyles, CreateCSSProperties } from "@material-ui/styles";
import { Route, Switch } from "react-router";
import PuffLoader from "react-spinners/ClipLoader";
import ReactGa from "react-ga";
// import TermsAndConditionsPage from "./components/pages/terms-and-conditions";
// import PrivacyPolicyPage from "./components/pages/privacy-policy";
// import SingleProductPage from "./components/pages/single-product-page";
// import CustomerServicePage from "./components/pages/customer-service";
// import ApprovedSellersPage from "./components/pages/approved-sellers";
// import ProviderAuth from "./components/authentication/providerAuth";
// import PrivateRoute from "./components/authentication/PrivateRoute";
// import ShoppingBagPage from "./components/pages/shopping-bag-page";
// import ContactPage from "./components/pages/contact/contact-page";
// import MyAccount from "./components/pages/my-account/my-account";
// import ProductsPage from "./components/pages/products-page";
// import CheckoutPage from "./components/pages/checkout-page";
// import CatalogPage from "./components/pages/catalog-page";
// import ErrorPage from "./components/pages/404-error-page";
// import BuyPolicyPage from "./components/pages/buy-policy";
import AppBanner from "./components/common/app-banner";
import SortPopup from "./components/popup/sort-popup";
import AuthPage from "./components/pages/auth-page";
// import HomePage from "./components/pages/home-page";
import Header from "./components/header/header";
// import Footer from "./components/footer/footer";
import Popup from "./components/popup/popup";

import { useAppDispatch, useAppSelector } from "./store/store";
import siteConfigStore from "./store/site-config-store";
import categoriesStore from "./store/categories-store";
import productsStore from "./store/products-store";
import ordersStore from "./store/orders-store";
import usersStore from "./store/users-store";
import { config } from "./config";
import CareersPage from "./components/pages/careers-page";
import PositionPage from "./components/pages/position-page";
import languagesStore from "./store/languages-store";
import thanksPagesStore from "./store/thanks-page-store";
import ThanksPage from "./components/pages/thanks-pages/thanks-page";
import { Product } from "./services/products/types";
import { CartItem } from "./services/users/types";
import { isMobile } from "react-device-detect";
import authFormStore from "./store/auth-form-store";
import { countProductDiscountPrice } from "./utils/helper";
import { theme } from "./theme";
import clsx from "clsx";
import brandsStore from "./store/brands-store";

const HomePage = lazy(() => import("./components/pages/home-page"));
const TermsAndConditionsPage = lazy(() => import("./components/pages/terms-and-conditions"));
const PrivacyPolicyPage = lazy(() => import("./components/pages/privacy-policy"));
const AccessibilityPage = lazy(() => import("./components/pages/accessibility"));
const SingleProductPage = lazy(() => import("./components/pages/single-product-page"));
const CustomerServicePage = lazy(() => import("./components/pages/customer-service"));
const ApprovedSellersPage = lazy(() => import("./components/pages/approved-sellers"));
const ProviderAuth = lazy(() => import("./components/authentication/providerAuth"));
const PrivateRoute = lazy(() => import("./components/authentication/PrivateRoute"));
const ShoppingBagPage = lazy(() => import("./components/pages/shopping-bag-page"));
const MyAccount = lazy(() => import("./components/pages/my-account/my-account"));
const ProductsPage = lazy(() => import("./components/pages/products-page"));
const ImporterDealsPage = lazy(() => import("./components/pages/importer-deals-page"));
const CheckoutPage = lazy(() => import("./components/pages/checkout-page"));
const ContactPage = lazy(() => import("./components/pages/contact/contact-page"));
const CatalogPage = lazy(() => import("./components/pages/catalog-page"));
const ErrorPage = lazy(() => import("./components/pages/404-error-page"));
const BuyPolicyPage = lazy(() => import("./components/pages/buy-policy"));
const Footer = lazy(() => import("./components/footer/footer"));

type StyleProps = {
  bannerIsVisible: boolean;
  AppBannerHeight: number;
};

type ProductToAdd = {
  product: Product;
  count: number;
};

const App = (): JSX.Element => {
  ReactGa.initialize("G-WDMYJZRE4G");
  // const [activeAuth, setActiveAuth] = useState<boolean>(false);
  const [bannerIsVisible, setBannerIsVisible] = useState<boolean>(true);

  const { user, activeSort, bottomBanner, url, categories } = useAppSelector(state => ({
    bottomBanner: state.siteConfig.bottomBanner,
    activeSort: state.UIsorting.activeSort,
    categories: state.categories,
    url: state.router.location.pathname,
    user: state.user.data,
  }));

  const dispatch = useAppDispatch();
  const [AppBannerHeight, setAppBannerHeight] = useState(0);
  const classes = useStyles({ bannerIsVisible, AppBannerHeight });
  const pathName = window.location.pathname;
  const search = window.location.search;

  //Google analytics start


  useEffect(() => {
    ReactGa.pageview(window.location.pathname);
  }, [window.location.pathname])

  //Google analytics end

  useEffect(() => {
    let bannerHeight = window.innerWidth * 0.12;
    setAppBannerHeight(bannerHeight);
  });

  useEffect(() => {
    window.scroll({ top: 0, behavior: "auto" });
    dispatch(thanksPagesStore.actions.fetchThanksPages());
    dispatch(usersStore.actions.fetchUser());
    dispatch(languagesStore.actions.fetchPhrases());
    dispatch(siteConfigStore.actions.fetchSiteConfig())
    dispatch(categoriesStore.actions.fetchCategories())
    dispatch(productsStore.actions.fetchProducts());
    dispatch(brandsStore.actions.fetchBrands());

    if(pathName.includes(config.routes.productsPage)){
      dispatch(categoriesStore.actions.setActiveCategory(Number(pathName.split("/").pop())))
      if(search.includes("sub")){
        dispatch(categoriesStore.actions.setActiveSubCategory(Number(search.split("=").pop())))
      }
      if(search.includes("sub_sub")){
        dispatch(categoriesStore.actions.setActiveSubSubCategory(Number(search.split("=").pop())))
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (user?.id) {
      dispatch(ordersStore.actions.fetchOrders());
    }
  }, [user?.id]);

  useEffect(() => window.scroll({ top: 0, behavior: "auto" }), [url]);

  useEffect(() => {
    const productsInStorage = localStorage.getItem("cartProducts");
    const cartItems: CartItem[] = user?.cartItems!?.map(cI => cI);

    if (user?.id && productsInStorage) {
      const parsedProducts: ProductToAdd[] = JSON.parse(productsInStorage);

      const countProductPrice = (isAgent: boolean, p: Product, discount = 0) => {
        let { price } = countProductDiscountPrice(p);
        if (isAgent) {
          return p?.storePrice - (p?.storePrice / 100) * discount;
        } else {
          return price;
        }
      };
      const addProductsFromLocalStToUpdatedCartItems = (products: ProductToAdd[]) => {
        let currentCartItems: CartItem[] = cartItems?.slice(0);

        products.map(obj => {
          const price = countProductPrice(
            user?.approvedAgent!,
            obj.product,
            Number(user?.discount!)
          );
          const itemToAdd = {
            productId: obj.product.id,
            productName: obj.product.name,
            productCount: obj.count,
            price,
            discount: obj.product.discount,
            discountDate: obj.product.discountDate,
            retailPrice: obj.product.retailPrice,
            salePrice: obj.product.salePrice,
          };
          const indexOfProduct = currentCartItems?.findIndex(
            p => p.productId === itemToAdd.productId
          );

          if (indexOfProduct !== -1) {
            currentCartItems = currentCartItems.map((item, i) => {
              if (i === indexOfProduct) {
                let { discountPercentages, discountAmmount, price } =
                  countProductDiscountPrice(item);
                if (item.productCount + itemToAdd.productCount <= obj.product.quantity) {
                  item = {
                    ...item,
                    productCount: item.productCount + itemToAdd.productCount,
                    price: price,
                    salePrice: item.salePrice,
                    retailPrice: item.retailPrice,
                    discountDate: item.discountDate,
                    discount: item.discount,
                  };
                }
              }

              return item;
            });
          } else {
            currentCartItems = [...currentCartItems, itemToAdd];
          }
        });

        return currentCartItems;
      };

      const updatedCartItems = addProductsFromLocalStToUpdatedCartItems(parsedProducts);

      dispatch(
        usersStore.actions.updateUserCart({
          userId: user.id,
          cartItems: updatedCartItems,
        })
      );

      localStorage.removeItem("cartProducts");
    }
  }, [user]);

  const { authFormIsVisible } = useAppSelector(({ authForm: { authFormIsVisible } }) => ({
    authFormIsVisible,
  }));

  const closeAllDropDowns = () => dispatch(authFormStore.actions.closeAllDropDowns());

  return (
    <>
      <Header />
      <div className={classes.wrapper} onClick={closeAllDropDowns}>
        <div className={classes.content}>
          {!isMobile && <Popup active={authFormIsVisible} />}
          <Popup active={activeSort} className={classes.sortPopup}>
            <SortPopup active={activeSort} />
          </Popup>

          <Suspense fallback={<div style={{ height: "100vh" }} />}>
            <Switch>
              <Route path={config.routes.redirect} component={ProviderAuth} />

              <Route exact path={config.routes.homePage} component={HomePage} />

              <Route path={config.routes.catalogPage} component={CatalogPage} />

              <Route path={config.routes.productsPage} component={ProductsPage} />

              <Route path={config.routes.importerDealsPage} component={ImporterDealsPage} />

              <Route path={`${config.routes.product}/:id`} component={SingleProductPage} />

              <Route path={config.routes.thanksPage} component={ThanksPage} />

              <Route
                path={`${config.routes.importerDealsProduct}/:id`}
                component={SingleProductPage}
              />

              <Route path={config.routes.authPage} component={AuthPage} />

              <Route path={config.routes.contact} component={ContactPage} />

              <Route path={config.routes.termsAndConditions} component={TermsAndConditionsPage} />

              <Route path={config.routes.privacyPolicy} component={PrivacyPolicyPage} />

              <Route path={config.routes.accessibility} component={AccessibilityPage} />

              <Route path={config.routes.customerService} component={CustomerServicePage} />

              <Route path={config.routes.buyPolicy} component={BuyPolicyPage} />

              <Route path={config.routes.approvedSellers} component={ApprovedSellersPage} />

              <Route exact path={config.routes.careers} component={CareersPage} />

              <Route path={`${config.routes.careers}/:id`} component={PositionPage} />

              <PrivateRoute path={config.routes.shoppingBagPage}>
                <ShoppingBagPage />
              </PrivateRoute>

              <PrivateRoute path={config.routes.checkoutPage}>
                <CheckoutPage />
              </PrivateRoute>

              <PrivateRoute path={config.routes.myAccount}>
                <MyAccount />
              </PrivateRoute>

              <Route path="*" component={ErrorPage} />
            </Switch>
            <Footer className={clsx(bannerIsVisible && classes.footerMargin)} />

            {!!bottomBanner && (
              <AppBanner
                banner={bottomBanner}
                bannerIsVisible={bannerIsVisible}
                setBannerIsVisible={setBannerIsVisible}
              />
            )}
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default App;

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      paddingTop: 74,
      position: "relative",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      [theme.device.mobile()]: {
        paddingTop: 84,
      },
    },

    content: {
      paddingBottom: 0,

      [theme.device.mobile()]: {
        paddingBottom: 0,
      },
    },
    footerMargin: ({ AppBannerHeight }: StyleProps): CreateCSSProperties => ({
      marginBottom: AppBannerHeight,
      [theme.device.mobile()]: {
        marginBottom: 0,
      },
    }),
    sortPopup: {
      alignItems: "flex-end",
      backdropFilter: "none",
      backgroundColor: theme.colors.shadow,

      [theme.device.desktop()]: {
        display: "none",
      },
    },
  }),
  { name: "app" }
);
