import siteConfigService from "../services/site-config-service";
import { TSiteConfig } from "../services/site-config-service/types";
import { createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { TThunk } from "./store";

type State = TSiteConfig;

const initialState: State = {
  runningText: null,
  carousel: [],
  carouselMobile: [],
  catalogCarousel: [],
  catalogCarouselMobile: [],
  middleBanner: null,
  middleBannerMobile: null,
  bottomBanner: null,
  contactUs: null,
  posts: [],
  socialMedia: null,
  homePageProduct: null,
  categories: [],
  maxOrderPrice: null,
  maxOrderWeight: null,
  TermsAndConditions: "",
  TermsAndConditionsHebrew: "",
  BuyPolicy: "",
  BuyPolicyHebrew: "",
  privacyPolicy: "",
  privacyPolicyHebrew: "",
  accessibility: "",
  accessibilityHebrew: "",
  CustomerService: "",
  CustomerServiceHebrew: "",
  vat: null,
  shipByPostPrice: null,
  courierPrice: null,
  careers: [],
  navigationLinks: [],
  title: "",
  description: "",
  keywords: "",
};

const fetchSiteConfig: TThunk<TSiteConfig> = createAsyncThunk(
  "site-config/fetch",
  async () => await siteConfigService.getSiteConfig()
);

const reducer = createReducer(initialState, {
  [fetchSiteConfig.fulfilled.type]: (state, action: PayloadAction<TSiteConfig>) => {
    state = action.payload;
    return state;
  },
});

const actions = {
  fetchSiteConfig,
};

const siteConfigStore = {
  reducer,
  actions,
};

export default siteConfigStore;

export type { State };
