import { createAsyncThunk, SerializedError, createReducer, PayloadAction } from "@reduxjs/toolkit";

import createCmsClient from "../services/common/cmsClient";
import { config } from "../config";
import { TThunk } from "./store";

export interface ApprovedSeller {
  address: string;
  created_at: string;
  id: number;
  googleMapsLatitude: number;
  googleMapsLongitude: number;
  name: string;
  phoneNumber: string;
  published_at: string;
  updated_at: string;
  website: string;
  workingHours: string;
  locationDirection: string;
}

type State = {
  data: ApprovedSeller[] | null;
  loading: boolean;
  loaded: boolean;
  error: Error | string;
};

const initialState: State = {
  data: null,
  loading: false,
  loaded: false,
  error: "",
};

const cmsClient = createCmsClient();

const fetchApprovedSellers: TThunk<any> = createAsyncThunk(
  "user/fetch-approved-salllers",
  async () => {
    try {
      const { data } = await cmsClient.get(`${config.endpoints.approvedSellers}?_limit=-1`);
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

const reducer = createReducer(initialState, {
  // FETCH APPROVED SELLERS \\
  [fetchApprovedSellers.pending.type]: state => {
    state.loading = true;
    state.loaded = false;
  },
  [fetchApprovedSellers.fulfilled.type]: (state, action: PayloadAction<ApprovedSeller[]>) => {
    state.data = action.payload;
    state.loading = false;
    state.loaded = true;
  },
  [fetchApprovedSellers.rejected.type]: (
    state,
    action: PayloadAction<null, string, unknown, SerializedError>
  ) => {
    state.error = action.error.message || "General Error";
    state.loading = false;
    state.loaded = true;
  },
});

const actions = {
  fetchApprovedSellers,
};

export const approvedSellersStore = {
  actions,
  reducer,
};

export default approvedSellersStore;

export type { State };
