import Cookies from "js-cookie";
import qs from "qs";

import formatError, { ErrorMessagePayload } from "../../utils/formatError";
import { TSiteConfig } from "../site-config-service/types";
import { Language } from "../../store/languages-store";
import { TOrder, TUpdateReq } from "../orders/types";
import { TThanksPage } from "../thanks-pages/types";
import createCmsClient from "../common/cmsClient";
import { getPhrase } from "../../utils/language";
import { Category, CategoryLevel } from "../categories/types";
import { Product, Review } from "../products/types";
import { Brand } from "../brands/types";
import { config } from "../../config";
import {
  UpdateUserInfo,
  ResetPassword,
  ProviderSign,
  LoggedUser,
  Favorites,
  CartItem,
  User,
} from "../users/types";
import { buildProduct } from "../../utils/helper";

export const cmsClient = createCmsClient();

const getHeaders = () => ({
  headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
});

// export const fetchSiteConfig = async (): Promise<any> => {
//   try {
//   const  data  = await fetch(process.env.REACT_APP_STRAPI_CMS_URL + config.endpoints.siteConfig)
//   .then(res => res.json())
//   .catch(e => console.log(e))
//   return data;
//   } catch (e) {
//     console.log(e)
//   }

// };
export const fetchSiteConfig = async (): Promise<any> => {
  const data = await fetch(process.env.REACT_APP_STRAPI_CMS_URL + config.endpoints.siteConfig, {
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(res => res.json())
    .catch(e => console.error(e));
  return data;
};
export const fetchThanksPages = async (): Promise<TThanksPage[]> => {
  const { data } = await cmsClient.get(config.endpoints.thanksPages);

  return data;
};

export const fetchProducts = async (): Promise<Product[]> => {
  const { data } = await cmsClient.get(config.endpoints.allProducts);
  return data;
};

export const fetchBrandProductsByCategory = async (categoryLevel: CategoryLevel, categoryId: number): Promise<Product[]> => {
  const { data } = await cmsClient.get(config.endpoints.getCategoryProducts(categoryLevel, categoryId));
  return data;
};

export const fetchProductsById = async (ids: number[]): Promise<Product[]> => {
  const query = qs.stringify({ _where: { id: ids } });
  const endpoint = `${config.endpoints.products}?${query}`;
  const { data } = await cmsClient.get(endpoint);

  return data;
};

export const fetchCategories = async (): Promise<any> => {
  try {
    const data = await fetch(process.env.REACT_APP_STRAPI_CMS_URL + config.endpoints.categories, {
      headers: {
        "Content-Type": "application/json",
        // "Accept": "*/*",
        // "Connection": "keep-alive",
        // "access-control-allow-origin": "*",
        // "access-control-allow-methods": "GET",
        // "ccess-Control-Allow-Headers": "*",
      },
    })
      .then(res => res.json())
      .catch(e => console.error(e));
    return data;
  } catch (e) {
    console.error(e);
  }
};
// export const fetchCategories = async (): Promise<any> => {
//   try {
//     const  data  = await fetch(process.env.REACT_APP_STRAPI_CMS_URL + config.endpoints.categories)
//     .then(res => res.json())
//     .catch(e => console.log(e))
//     return data;
//     } catch (e) {
//       console.log(e)
//     }
// };

export const fetchBrand = async (id: number): Promise<Brand[]> => {
  const { data } = await cmsClient.get(config.endpoints.brands + "/" + id);

  return data;
};

export const fetchBrands = async (): Promise<Brand[]> => {
  const { data } = await cmsClient.get(config.endpoints.brands);

  return data;
};

export const signUser = async (userData: User): Promise<User> => {
  try {
    const isLogin = userData.signType === "login";

    const { data } = isLogin
      ? await cmsClient.post(config.endpoints.authLogin, userData)
      : await cmsClient.post(config.endpoints.authRegister, userData);

    const userUrl = config.endpoints.users + data?.user.id;

    data?.jwt && Cookies.set("jwt", data.jwt, { expires: 1, path: "/" });

    isLogin
      ? await cmsClient.put(userUrl, { lastLogin: userData.lastLogin }, getHeaders())
      : await cmsClient.put(
          userUrl,
          {
            lastLogin: userData.registrationDate,
            registrationDate: userData.registrationDate,
          },
          getHeaders()
        );

    return {
      jwt: data.jwt,
      ...data.user,
    };
  } catch (error) {
    return formatError(error);
  }
};

export const signUserWithProvider = async (authData: ProviderSign): Promise<User> => {
  try {
    const cmsUrl = process.env.REACT_APP_STRAPI_CMS_URL;
    const response = await cmsClient.get(
      `${cmsUrl}/auth/${authData.providerName}/callback${authData.search}`
    );

    if (response.status !== 200) {
      throw new Error(`Couldn't login to Strapi. Status: ${response.status}`);
    }

    // return response.data;
    return {
      jwt: response.data.jwt,
      ...response.data.user,
    };
  } catch (error) {
    return formatError(error);
  }
};

export const fetchUser = async (): Promise<LoggedUser> => {
  try {
    const { data } = await cmsClient.get(config.endpoints.currentUser, getHeaders());

    return data;
  } catch (error) {
    throw new Error(error.response.data.data[0].message[0].message);
  }
};

export const fetchOrders = async (): Promise<TOrder[]> => {
  const { data } = await cmsClient.get(config.endpoints.orders, getHeaders());

  return data;
};

export const createOrder = async (order: TOrder): Promise<TOrder> => {
  console.log("order ===>", order);
  const { data } = await cmsClient.post(config.endpoints.orders, order, getHeaders());

  return data;
};

export const updateOrder = async (body: TUpdateReq): Promise<void> => {
  await cmsClient.put(`${config.endpoints.updateOrder}/${body.id}`, body, getHeaders());
};

export const toggleFavoriteCatalogItem = async (
  userId: string,
  favoriteCatalogItems: Favorites[]
): Promise<LoggedUser> => {
  const { data } = await cmsClient.put(
    config.endpoints.users + userId,
    { favoriteCatalogItems },
    getHeaders()
  );

  return data;
};

export const resetPassword = async (
  userPwds: ResetPassword
): Promise<LoggedUser | ErrorMessagePayload | undefined> => {
  try {
    const { data } = await cmsClient.post(config.endpoints.authReset, userPwds, getHeaders());

    return data;
  } catch (error) {
    throw new Error(error.response.data.data[0].message[0].message);
  }
};

export const updateUserInfo = async (userData: UpdateUserInfo): Promise<LoggedUser | undefined> => {
  try {
    if (userData.userId !== undefined) {
      const { data } = await cmsClient.put(
        config.endpoints.updateUser(userData.userId),
        { ...userData },
        getHeaders()
      );

      return data;
    }

    return;
  } catch (error) {
    throw new Error(error.response.data.data[0].message[0].message);
  }
};

export const addSubscriptionEmail = async ({
  email,
  lang,
}: {
  email: string;
  lang: Language;
}): Promise<undefined | string> => {
  try {
    const response = await cmsClient.post(config.endpoints.addSubscriptionEmail, { email });

    if (response.status === 200) return;

    throw new Error(getPhrase("has_subscription", lang));
  } catch (error) {
    throw new Error(getPhrase("has_subscription", lang));
  }
};

export const updateUserCart = async ({
  userId,
  cartItems,
}: {
  userId: number;
  cartItems: CartItem[];
}): Promise<LoggedUser | undefined> => {
  try {
    if (userId) {
      const { data } = await cmsClient.put(
        config.endpoints.updateUser(userId),
        {
          cartItems,
        },
        getHeaders()
      );

      return data;
    }
    return;
  } catch (error) {
    throw new Error(error.response.data.data[0].message[0].message);
  }
};

type UpdateProductProps = {
  catalogNumber: string;
  reviews: Review[];
};

export const updateProduct = async (
  productsArray: UpdateProductProps[]
): Promise<Product[] | undefined> => {
  try {
    const products = await Promise.all(
      productsArray.map(async product => {
        const { catalogNumber, reviews } = product;
        const { data } = await cmsClient.put(
          config.endpoints.updateProduct(catalogNumber),
          {
            reviews,
          },
          getHeaders()
        );

        return data;
      })
    );

    return products.map(buildProduct);
  } catch (error) {
    throw new Error(error.response.data.data[0].message[0].message);
  }
};
