import { makeStyles, CreateCSSProperties } from "@material-ui/styles";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import useSwipe from "../../hooks/useSwipe";
import useViewport from "../../hooks/useViewport";
import { TMediaData } from "../../services/site-config-service/types";
import { useAppSelector } from "../../store/store";

type CSSProps = {
  height: number;
  mobileHeight: number;
};

const useStyles = makeStyles(
  theme => ({
    root: ({ height, mobileHeight }: CSSProps): CreateCSSProperties => ({
      position: "relative",
      overflow: "hidden",
      width: "100%",
      height: height,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      flexDirection: "column",
      [theme.device.mobile()]: {
        height: mobileHeight,
      },
    }),
    slides: {
      position: "absolute",
      display: "flex",
      justifyContent: "flex-start",
      width: "100%",
      height: "100%",
      transition: "1s",
    },
    image: {
      userSelect: "none",
      width: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "fill",
    },
    dots: {
      display: "flex",
      position: "absolute",
      paddingBottom: 10,
    },
    dot: {
      cursor: "pointer",
      height: 27,
      width: 27,
      marginRight: 10,
      backgroundColor: theme.colors.text,
      border: `2px solid ${theme.colors.green}`,
      borderRadius: "100%",
      [theme.device.mobile()]: {
        height: 20,
        width: 20,
      },
    },
    activeDot: {
      backgroundColor: theme.colors.green,
      border: `2px solid ${theme.colors.text}`,
      transition: "0.5s",
    },
    pointer: {
      cursor: "pointer"
    },
    noPointer: {
      cursor: "auto"
    }
  }),
  { name: "carousel" }
);

type TProps = {
  slides: TMediaData[];
  className?: {
    root?: string;
    slides?: string;
    dots?: string;
    dot?: string;
  };
};

const CarouselLinear = ({ slides, className }: TProps): JSX.Element => {
  const pathName = useAppSelector(state => state.router.location.pathname);
  const { width } = useViewport();
  const initialHeight = pathName === "/" ? 400 : 200;
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [height, setHeight] = useState(initialHeight);
  const [mobileHeight, setMobileHeight] = useState(initialHeight);
  const sliderRef = useRef<HTMLDivElement>(null);
  const imagesRefs = useRef<Array<HTMLImageElement | null>>([]);
  const classes = useStyles({ height: height, mobileHeight: mobileHeight });

  const setUrl = (url: string) => {
    let http = "http://";
    if (url.substring(0, 7) == "http://") {
      return url;
    }
    if (url.substring(0, 8) == "https://") {
      return url;
    }
    return http + url;
  };
  const items = slides.map((image, index) => (
    <img
      key={image.id}
      className={clsx(classes.image, image?.caption !== "" && image?.caption ? classes.pointer : classes.noPointer)}
      src={image.url}
      alt={`slide #${index}`}
      ref={el => (imagesRefs.current[index] = el)}
      onClick={() => {
        if (image?.caption !== "" && image?.caption) {
          window.open(setUrl(image?.caption), "_blank");
        }
      }
      }
    />
  ));

  const swipeable = useSwipe(direction => {
    if (direction === "left" && currentSlide + 1 < slides.length) {
      setCurrentSlide(current => current + 1);
      return;
    }

    if (direction === "right" && currentSlide - 1 >= 0) {
      setCurrentSlide(current => current - 1);
      return;
    }
  });

  const sliderDots = slides.map((_, index) => (
    <div
      key={index}
      className={clsx(classes.dot, index === currentSlide && classes.activeDot, className?.dot)}
      onClick={() => setCurrentSlide(index)}
    />
  ));

  useEffect(() => {
    const imageRef = imagesRefs.current[currentSlide];
    const imageOriginalWidth = imageRef?.naturalWidth;
    const imageOriginalHeight = imageRef?.naturalHeight;
    const ratio = imageOriginalWidth! / imageOriginalHeight!;

    // const height = width! / ratio;
    const height = window.innerWidth * 0.11;
    const mobileHeight = window.innerWidth * 0.56;

    setHeight(height);
    setMobileHeight(mobileHeight);
  }, [currentSlide, width, imagesRefs]);

  useEffect(() => {
    sliderRef.current!.style.left = `-${sliderRef.current!.clientWidth * currentSlide}px`;

    const timeout = setTimeout(() => {
      if (currentSlide < slides.length - 1) {
        setCurrentSlide(current => current + 1);
        return;
      }

      setCurrentSlide(0);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [currentSlide, slides.length]);

  return (
    <div className={clsx(classes.root, className?.root)}>
      <div className={clsx(classes.slides, className?.slides)} ref={sliderRef} {...swipeable}>
        {items}
      </div>
      {sliderDots.length > 1 && (
        <div className={clsx(classes.dots, className?.dots)}>{sliderDots}</div>
      )}
    </div>
  );
};

export default CarouselLinear;
