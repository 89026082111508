import { makeStyles } from "@material-ui/styles";
import React from "react";
import clsx from "clsx";

const useStyles = makeStyles(
  theme => ({
    panel: {
      boxSizing: "border-box",
      width: "100%",
      padding: "10px 15px",
      backgroundColor: theme.colors.green,
      display: "flex",

      [theme.device.mobile()]: {
        flexDirection: "column",
      },
    },
  }),
  { name: "panel" }
);

type TProps = {
  children: JSX.Element[] | JSX.Element;
  className?: {
    panel?: string;
  };
};

const Panel = ({ children, className }: TProps): JSX.Element => {
  const classes = useStyles({});

  return <div className={clsx(classes.panel, className?.panel)}>{children}</div>;
};

export default Panel;
