import { makeStyles } from "@material-ui/styles";
import React from "react";
import clsx from "clsx";

import { useAppDispatch, useAppSelector } from "../../store/store";
import usersStore from "../../store/users-store";
import { getPhrase } from "../../utils/language";
import languagesStore from "../../store/languages-store";

type TProps = {
  className?: {
    root?: string;
  };
};

const EmptyBag = ({ className }: TProps): JSX.Element => {
  const { user, lang } = useAppSelector(state => ({
    lang: state.languages.language,
    user: state.user.data,
  }));

  const dispatch = useAppDispatch();
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className?.root)}>
      <div
        className={classes.emptyBag}
        onClick={() => {
          dispatch(usersStore.actions.updateUserCart({ userId: user?.id!, cartItems: [] }));
        }}
      >
        {activePhrases && activePhrases["empty_the_cart"]}
      </div>
    </div>
  );
};

export default EmptyBag;

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: "15px 0",
      boxSizing: "border-box",
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
    emptyBag: {
      cursor: "pointer",
      fontWeight: 700,
      fontSize: 24,
      color: theme.colors.yellow,
    },
  }),
  { name: "empty-bag" }
);
