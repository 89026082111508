import { makeStyles, useTheme } from "@material-ui/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { getPhrase } from "../../utils/language";
import CarouselLinear from "../carousel/carousel";
import Arrow from "../common/arrow";
import Panel from "../common/panel";
import { push } from "connected-react-router";
import { config } from "../../config";
import Button from "../common/button";
import { escapeRegExp } from "lodash";
import { isMobile } from "react-device-detect";
import { ReactComponent as SearchIcon } from "../../static/icons/search.svg";
import languagesStore from "../../store/languages-store";

const CareersPage = (): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  
  const dispatch = useAppDispatch();
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());
  
  const { lang, slides, slidesMobile, careers } = useAppSelector(state => ({
    slides: state.siteConfig.catalogCarousel,
    slidesMobile: state.siteConfig.catalogCarouselMobile,
    lang: state.languages.language,
    careers: state.siteConfig.careers,
  }));

  const INIT_LAYOUT: number = isMobile ? 4 : 6;
  let ALL_CATS: string = activePhrases! && activePhrases["all_categories"];

  const [searchText, setSearchText] = useState<string>("");
  const [selectedCat, setSelectedCat] = useState<string>(ALL_CATS);
  const [posLayout, setPosLayot] = useState<number>(INIT_LAYOUT);
  const [select, setSelect] = useState<boolean>(false);

  const categories = careers.map(c => c.careersCategory);

  const filteredCareers = careers
    ?.filter(pos => pos.careersCategory.name === selectedCat || selectedCat === ALL_CATS)
    ?.filter(pos =>
      !!searchText ? new RegExp(`(${escapeRegExp(searchText)})`, "gi").test(pos.name) : pos
    );

useEffect(() => {
  setSelectedCat(activePhrases! && activePhrases['all_categories'])
}, [activePhrases])

  const locationsCount = filteredCareers
    .map(c => c.location)
    .filter((value, index, self) => self.indexOf(value) === index).length;

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchText(e.currentTarget.value);

  const onSelectClick = () => setSelect(current => !current);
  // const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());
  const onDropDownItemClick = (name: string) => () => {
    setSelectedCat(name);
    setSelect(false);
  };

  const onPosBtnClick = (id: number) => () => {
    dispatch(push(`${config.routes.careers}/${id}`, true));
  };

  const onShowMoreClick = () => setPosLayot(current => current + INIT_LAYOUT);

  useEffect(() => setPosLayot(INIT_LAYOUT), [selectedCat, INIT_LAYOUT, ALL_CATS]);

  return (
    <div className={classes.root}>
      <CarouselLinear
        slides={isMobile ? slidesMobile : slides}
        className={{ root: classes.carousel, dots: classes.dots, dot: classes.dot }}
      />
      <Panel className={{ panel: classes.panel }}>
        <div className={classes.title}>{activePhrases && activePhrases["careers"]}</div>
      </Panel>
      <div className={classes.careers}>
        <div className={classes.careersPositionsTitle}>
          {activePhrases && activePhrases["open_positions"]}
        </div>
        <div className={classes.careersPositionsDescription}>
          {activePhrases && activePhrases["location"]}&nbsp;
          <div className={classes.careersPositionsCount}>{locationsCount}</div>
          &nbsp;{activePhrases && activePhrases["roles"]}&nbsp;
          <div className={classes.careersPositionsCount}>{filteredCareers.length}</div>
        </div>
        <div className={classes.search}>
          <div className={classes.inputWrapper}>
            <input
              name="search"
              onChange={onInputChange}
              value={searchText}
              placeholder={activePhrases && activePhrases["search"]}
              className={classes.input}
            />
            <SearchIcon className={classes.careersSearchIcon} />
          </div>
          <div className={clsx(classes.inputWrapper , classes.dropdownDirection)}>
            <div className={classes.currentField} onClick={onSelectClick}>
              <input className={classes.currentText} value={selectedCat} readOnly />
              <Arrow
                color={theme.colors.green}
                active={select}
                className={classes.arrow}
                borderWidth={12}
              />
            </div>
            <div className={clsx(classes.selectContainer, select && classes.active)}>
              <div className={classes.selectItemWrapper} onClick={onDropDownItemClick(ALL_CATS)}>
                <div className={classes.selectItemText}>{ALL_CATS}</div>
              </div>
              {categories
                ?.filter((value, index, self) => self.map(v => v.id).indexOf(value.id) === index)
                ?.map(cat => (
                  <div
                    key={cat?.id}
                    className={classes.selectItemWrapper}
                    onClick={onDropDownItemClick(cat?.name)}
                  >
                    <div className={classes.selectItemText}>{cat.name}</div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className={classes.positions}>
          {filteredCareers
            ?.filter((_, i) => i < posLayout)
            .map(pos => (
              <div key={pos.id} className={classes.position}>
                <div className={classes.positionTitle}>{pos.name}</div>
                <div className={classes.positionAdd}>
                  <div className={classes.positionLocation}>
                    <div className={classes.positionLocationCircle} />
                    <div className={classes.positionLocationText}>{pos.location}</div>
                  </div>
                  <Button
                    text={activePhrases && activePhrases["view_more"]}
                    className={classes.positionBtn}
                    width={120}
                    height={35}
                    borderWidth={2}
                    borderRadius={25}
                    fill={theme.colors.green}
                    textSize={15}
                    outline={theme.colors.text}
                    textColor={theme.colors.text}
                    action={onPosBtnClick(pos.id)}
                  />
                </div>
              </div>
            ))}
        </div>
        {filteredCareers.length > posLayout && (
          <Button
            className={classes.showMore}
            text={activePhrases && activePhrases["show_more"]}
            borderRadius={25}
            width={120}
            height={35}
            borderWidth={2}
            fill={theme.colors.text}
            textSize={15}
            outline={theme.colors.green}
            textColor={theme.colors.green}
            action={onShowMoreClick}
          />
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles(
  theme => ({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    carousel: {
      [theme.device.mobile()]: {
        minHeight: 100,
      },
    },
    dots: {
      [theme.device.mobile()]: {},
    },
    dot: {
      height: 22,
      width: 22,
      [theme.device.mobile()]: {
        height: 17,
        width: 17,
      },
    },
    panel: {
      justifyContent: "center",
      padding: 0,
    },
    title: {
      color: theme.colors.text,
      fontSize: 40,
      height: 65,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    careers: {
      width: "100%",
      boxSizing: "border-box",
      padding: 40,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    careersPositionsTitle: {
      fontSize: 40,
      color: theme.colors.black,
      fontWeight: 700,
      textAlign: "center",
    },
    careersPositionsDescription: {
      marginTop: 3,
      color: theme.colors.grayText,
      fontSize: 19,
      fontWeight: 600,
      display: "flex",
    },
    careersPositionsCount: {
      fontWeight: 700,
    },
    search: {
      width: "100%",
      padding: "60px 0 40px 0",
      display: "flex",
      justifyContent: "center",
      [theme.device.mobile()]: {
        flexDirection: "column",
        justifyContent: "flex-start",
      },
    },
    inputWrapper: {
      width: 350,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      border: `2px solid ${theme.colors.green}`,
      padding: "10px 15px",
      boxSizing: "border-box",
      borderRadius: 25,
      marginRight: 10,
      marginBottom: 10,
      position: "relative",
      [theme.device.mobile()]: {
        width: "100%",
        height: 70,
        padding: "0 15px",
        marginBottom: 25,
      },
    },
    input: {
      width: "100%",
      border: "none",
      fontSize: 27,
      textAlign: "end",
      color: theme.colors.black,
    },
    dropdownDirection: {
      direction: "rtl",
    },
    currentField: {
      cursor: "pointer",
      display: "grid",
      gridTemplateColumns: "1fr 30px",
      alignItems: "center",
      width: "100%",
      height: "100%",
      gridColumnGap: 5,
    },
    currentText: {
      cursor: "pointer",
      border: "none",
      width: "100%",
      fontSize: 27,
      color: theme.colors.black,
      display: "flex",
      alignItems: "center",
    },
    arrow: {
      marginLeft: "auto",
    },
    careersSearchIcon: {
      width: 45,
      height: 50,
      ...theme.utils.svgStroke(theme.colors.green),
    },
    selectContainer: {
      overflowX: "hidden",
      overflowY: "auto",
      height: 325,
      opacity: 0,
      visibility: "hidden",
      transition: "0.3s",
      zIndex: 1,
      boxSizing: "border-box",
      padding: "10px 0",
      marginTop: 20,
      position: "absolute",
      width: "100%",
      left: 0,
      top: "100%",
      backgroundColor: theme.colors.green,
      borderRadius: 25,
      display: "flex",
      flexDirection: "column",
    },
    selectItemWrapper: {
      padding: "0 15px",
      boxSizing: "border-box",
      width: "100%",
      color: theme.colors.text,
      fontSize: 27,
      cursor: "pointer",
      userSelect: "text",
      "&:hover": {
        backgroundColor: "#175356",
        transition: "0.2s",
      },
    },
    selectItemText: {
      paddingBlock: 10,
      height: "100%",
      borderBottom: `2px solid ${theme.colors.text}`,
    },
    positions: {
      width: "100%",
      boxSizing: "border-box",
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gridGap: "25px 15px",
      direction: "rtl",
      [theme.device.mobile()]: {
        width: "auto",
        display: "flex",
        flexDirection: "column",
        paddingBottom: 20,
      },
    },
    position: {
      borderRadius: 20,
      height: 170,
      width: "100%",
      display: "grid",
      gridTemplateRows: "1.5fr 2fr",
      backgroundColor: theme.colors.text,
      boxShadow: `2px 0px 37px -6px ${theme.colors.shadow}`,
      [theme.device.mobile()]: {
        width: "100%",
        marginBottom: 15,
      },
    },
    positionTitle: {
      display: "flex",
      alignItems: "center",
      height: "100%",
      width: "100%",
      boxSizing: "border-box",
      padding: "0 20px",
      color: theme.colors.green,
      fontSize: 30,
      fontWeight: 700,
    },
    positionAdd: {
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      width: "100%",
      height: "100%",
      backgroundColor: theme.colors.green,
      boxSizing: "border-box",
      padding: "15px 20px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    positionLocation: {
      display: "flex",
      alignItems: "center",
      gridColumnGap: 5,
    },
    positionLocationText: {
      fontSize: 17,
      color: theme.colors.text,
    },
    positionLocationCircle: {
      borderRadius: "100%",
      height: 12,
      width: 12,
      backgroundColor: theme.colors.yellow,
      marginRight: 5,
    },
    positionBtn: {
      marginTop: "auto",
    },
    showMore: {
      marginTop: 20,
    },
    active: {
      transition: "0.3s",
      opacity: 1,
      visibility: "visible",
    },
  }),
  { name: "careers-page" }
);

export default CareersPage;
